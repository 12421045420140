import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  appBar: {
    borderRadius: 15,
    margin: '30px 0 5px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  heading: {
    color: 'rgba(0,183,255, 1)',
  },
  image: {
    marginLeft: '15px',
  },
  grid: {
    marginTop: '5px',
  },
  edit: {
    position:'sticky',
    width:'100vh',
    height:'fit-content',
    display: 'flex',
    top: '5px',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      height: 'auto',
    },
    ['@media (max-height:900px)']: { // eslint-disable-line no-useless-computed-key
      top: '-30%',
    }
  },
}));
