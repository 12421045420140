import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: '0',
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 40px',
  },
  heading: {
    color: '#0054A6',
    textDecoration: 'none',
    align: 'left', 
    fontWeight: '600',
    fontSize: '2rem',
    width: '100%',
    textShadow: '-1px 0px #00ADEF',
    ['@media (max-width:900px)']: { // eslint-disable-line no-useless-computed-key
      display: 'none',
    }
  },
  
  image: {
    marginLeft: '15px',
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: '-40px',
    }
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '90%',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '-50px',
      width: '50%',
    }
    
  },

  signin:{
    fontSize: '.9rem',
    justifyContent: 'flex-end',
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '.6rem',
      display: 'inline-block',
      justifyContent: 'flex-end',
    }
  },

  logout:{
    fontSize: '.9rem',
    justifyContent: 'flex-end',
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '.6rem',
      display: 'inline-block',
      justifyContent: 'flex-end',
      padding: '0',
    }
  },

  profile: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '400px',
    
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      display:'none',
      width:'100%',
    }
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));