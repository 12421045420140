import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import Sensitivity from "./Sensitivity";
import Host from "./Host";
import Network from "./Network";
import Client from "./Client";
import AppDesignCoding from "./AppDesignCoding";
import Environment from "./Environment";
import AppContinuity from "./AppContinuity";
import Business from "./Business";
import Access from "./Access";
import General from "./General";
import PrivacyChecklist from "./PrivacyChecklist";
import PrivacyRisk from "./PrivacyRisk";
import End from "./End";

import "./styles.css";

function Form() {
  const history = useHistory();
  const routeChange = () =>{ 
    let path = `thanks`; 
    history.push(path);
  }
 
  const [page, setPage] = useState(0);

  const FormTitles = ["Sensitivity Rating", 
                      "Host System Controls ", 
                      "Network & Communications Controls", 
                      "Client Workstation Controls",
                      "Application (design & coding) Controls",
                      "Environment and Physical Security Controls",
                      "Application Continuity Controls",
                      "Business Procedures Continuity Controls",
                      "Access Controls",
                      "General Procedures Controls",
                      "Privacy Checklist Rating",
                      "Privacy Risk Rating",
                      "End of Survey"];

  const PageDisplay = () => {
    if (page === 0) {
      return <Sensitivity />;
    } else if (page === 1) {
      return <Host />;
    } else if (page === 2) {
      return <Network />;
    } else if (page === 3) {
      return <Client />;
    } else if (page === 4) {
      return <AppDesignCoding />;
    } else if (page === 5) {
      return <Environment />;
    } else if (page === 6) {
      return <AppContinuity />;
    } else if (page === 7) {
      return <Business />;
    } else if (page === 8) {
      return <Access />;
    } else if (page === 9) {
      return <General />;
    } else if (page === 10) {
      return <PrivacyChecklist />;
    } else if (page === 11) {
      return <PrivacyRisk />;
    } else {
      return <End />;
    }
  };

  return (
    <div className="form">
      <div className="progressbar">
        <div
          style={{ width: page === 0 ? "8%" : page === 1 ? "16%" : page === 2 ? "24%" : page === 3 ? "32%" : page === 4 ? "40%" : page === 5 ? "48%" : page === 6 ? "56%" : page === 7 ? "64%" : page === 8 ? "72%" : page === 9 ? "80%" : page === 10 ? "88%" : page === 11 ? "96%" : "100%" }}
        ></div>
      </div>
      <div className="form-container">
        <div className="header">
          <h1>{FormTitles[page]}</h1>
        </div>
        <div className="body">{PageDisplay()}</div>
        <div className="footer">
          <button className={page === 0 ? "hide" : "button-21"} 
            disabled={page === 0}
            onClick={() => {
              setPage((currPage) => currPage - 1);
            }}
          >
            Prev
          </button>
          <button className="button-21" 
            onClick={() => {
              if (page === FormTitles.length - 1) {
               routeChange()
              } 
              else {
                setPage((currPage) => currPage + 1);
                window.scrollTo({
                  top: 0, 
                  behavior: 'smooth'
                  /* you can also use 'auto' behaviour
                     in place of 'smooth' */
                });
              }
            }}
          >
            {page === FormTitles.length - 1 ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Form;