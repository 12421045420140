import { RESULT, CREATERESULT, UPDATERESULT, DELETE } from '../constants/actionTypes';

import * as api from '../api/index.js';

export const getResult = () => async (dispatch) => {
  try {
    const { data } = await api.fetchResults();
    data.sort(function(b,a) {
      return (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0);
   });
    dispatch({ type: RESULT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const createResult = (result) => async (dispatch) => {
  try {
    const { data } = await api.createResult(result);
    dispatch({ type: CREATERESULT, payload: data }); 
  } catch (error) {
    console.log(error.message);
  }
};

export const updateResult = (id, result) => async (dispatch) => {
  try {
    const { data } = await api.updateResult(id, result);
    dispatch({ type: UPDATERESULT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteResult = (user) => async (dispatch) => {
  try {
    await api.deleteResult(user);
    dispatch({ type: DELETE, payload: user });
  } catch (error) {
    console.log(error.message);
  }
};


