export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const LIKE = 'LIKE';
export const FILTER = 'FILTER';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';

export const RESULT = 'RESULT';
export const CREATERESULT = 'CREATERESULT';
export const UPDATERESULT = 'UPDATERESULT';

export const AVERAGE = 'AVERAGE';