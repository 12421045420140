import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundBlendMode: 'darken',
  },
  border: {
    border: 'solid',
  },
  fullHeightCard: {
    height: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '15px',
    height: '100%',
    position: 'relative',
    width: '100%',
    padding: '2rem 0',
  },
  overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'white',
  },
  overlay2: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    color: 'white',
  },
  grid: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  questions: {
    padding: '0 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardActions: {
    padding: '0 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  ratings: {
    width: '100%',
    display: 'flex',
    padding:'0',
  },
  title: {
    padding: '0 20px 0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '1.2rem',
      display: 'inline-block',
      justifyContent: 'flex-end',
    }
  },

  radio: {
    padding: '2px 10px 2px 10px',
    
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '.5rem',
      margin: '0',
      padding: '5px 0 5px 0',
    }
  },
  comments: {
    
    margin: '0 2rem',
  },
  help: {   
    margin: '1rem 2rem',
  },

  hide: {
    display: 'none',
}

});
