import React from 'react';
import { useTimer } from 'react-timer-hook';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createResult } from '../../../actions/results';

export default function MyTimer({ expiryTimestamp }) {
  const dispatch = useDispatch();
  
  const {
    seconds,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
  
  if (seconds ===15){
    for (let i = 0; i < localStorage.length; i++) {
      let storedValue = localStorage.key(i);
      let result = storedValue.includes("profile");
      if (!result) {
        const list = JSON.parse(localStorage.getItem(storedValue))
        dispatch(createResult(list));
        console.log(i)
    }}

    return  (
      <div style={{textAlign: 'center'}}>
      <h3>Please wait as we do the calculation of the result...</h3>
        <div style={{fontSize: '3rem', 
                  color: '#F1F3F4',
                  borderRadius:'20px',
                  border: '1.5px solid white',
                  width:'60px',
                  heigth: '60px',
                  margin: 'auto'
                  }}>
          <span>{seconds}</span>
        </div>
      </div>
    )

  }
  else if (seconds ===0){
    
    return(
    <div>
        <h3>You can now view the summary result!</h3>
        <div className="intro-button">
            <Link to='/summary'>View Results</Link>
        </div></div>
    )
  } else {
    
  return (
    <div style={{textAlign: 'center'}}>
    <h3>Please wait as we do the calculation of the result...</h3>
      <div style={{fontSize: '3rem', 
                color: '#F1F3F4',
                borderRadius:'20px',
                border: '1.5px solid white',
                width:'60px',
                heigth: '60px',
                margin: 'auto'
                }}>
        <span>{seconds}</span>
      </div>
    </div>
  );
}
}