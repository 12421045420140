import React, { useState, useEffect } from "react";
import "./styles.css";
import { useDispatch } from 'react-redux';
import Questions from '../../Questions/Questions';
import { SensitivityRating } from '../../../actions/posts';
  
function Sensitivity() {
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
    
  useEffect(() => {
    dispatch(SensitivityRating());
}, [current, dispatch]);

  return (
    <div className="sign-up-container">
      <Questions current={setCurrent} />
    </div>
  );
}

export default Sensitivity;