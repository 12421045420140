import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import './styles.css'
import { getAverage} from '../../../actions/average';

const Risk = () => {
  
  const dispatch = useDispatch();
  const average = useSelector((state) => state.averages);
  
  useEffect(() => {
    dispatch(getAverage());
  }, [dispatch]);

  const resolveClass = (value) => {
    let className = ''
    if (value!=null){
    switch (true) {
        case (value === 5 ):
            className = 'gray'
            break;
        case (value <= 2):
            className = 'red'
            break; 
        case (value <= 4):
            className = 'yellow'
            break;
              
        default:
            break
    }}
    return className
  }

  return (
 
    <div>
      <TableContainer component={Paper} className="riskContainer">
        <Table sx={{ minWidth: 600 }} aria-label="simple table" >
          <TableHead>
            <TableRow>
              <TableCell><b>Risk</b></TableCell>
              <TableCell align="right">Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {average.map((row) => (
              <TableRow
                key={row.avgPRR1}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell component="th" scope="row">Type of Application</TableCell>
                  <TableCell className={resolveClass(row.avgPRR1.rate)} align="right">{row.avgPRR1.rate}</TableCell>
              </TableRow>
               ))}
          </TableBody>

          <TableBody>
            {average.map((row) => (
              <TableRow
                key={row.avgSr}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell component="th" scope="row">Type of Information</TableCell>
                  <TableCell className={resolveClass(row.avgPRR2.rate)} align="right">{row.avgPRR2.rate}</TableCell>
              </TableRow>
               ))}
          </TableBody>

          <TableBody>
            {average.map((row) => (
              <TableRow
                key={row.avgSr}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell component="th" scope="row">Parties Involved</TableCell>
                  <TableCell className={resolveClass(row.avgPRR3.rate)} align="right">{row.avgPRR3.rate}</TableCell>
              </TableRow>
               ))}
          </TableBody>

          <TableBody>
            {average.map((row) => (
              <TableRow
                key={row.avgSr}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell component="th" scope="row">Duration of Use</TableCell>
                  <TableCell className={resolveClass(row.avgPRR4.rate)} align="right">{row.avgPRR4.rate}</TableCell>
              </TableRow>
               ))}
          </TableBody>

          <TableBody>
            {average.map((row) => (
              <TableRow
                key={row.avgSr}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell component="th" scope="row">Reach of Information</TableCell>
                  <TableCell className={resolveClass(row.avgPRR5.[0].rate)} align="right">{row.avgPRR5.[0].rate}</TableCell>
              </TableRow>
               ))}
          </TableBody>

          <TableBody>
            {average.map((row) => (
              <TableRow
                key={row.avgSr}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell component="th" scope="row">Technology</TableCell>
                  <TableCell className={resolveClass(row.avgPRR6.[0].rate)} align="right">{row.avgPRR6.[0].rate}</TableCell>
              </TableRow>
               ))}
          </TableBody>

          <TableBody>
            {average.map((row) => (
              <TableRow
                key={row.avgSr}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell component="th" scope="row">Transmission</TableCell>
                  <TableCell className={resolveClass(row.avgPRR7.[0].rate)} align="right">{row.avgPRR7.[0].rate}</TableCell>
              </TableRow>
               ))}
          </TableBody>
          <TableBody>
            {average.map((row) => (
              <TableRow
                key={row.avgSr}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell component="th" scope="row">Breach Impact</TableCell>
                  <TableCell className={resolveClass(row.avgPRR8.[0].rate)} align="right">{row.avgPRR8.[0].rate}</TableCell>
              </TableRow>
               ))}
          </TableBody>
        </Table>
        <Typography align="left">Legend: <span className='gray'>Low</span>, <span className='yellow'>Medium</span>, <span className='red'>High</span></Typography>
      </TableContainer>

      <div>
        {average.map((row) => (
          <div key={row.avgPRR}>
            <div style={{marginTop:'40px', display: "flex", alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <Typography>Privacy Risk Rating:</Typography>
                <Typography align="right">{row.avgPRR.Avg}</Typography>
            </div>
            <div style={{ display: "flex", alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <Typography align="left">Privacy Checklist Rating:</Typography>
                <Typography align="right">{row.avgPCR.Avg}%</Typography>
            </div>
            <div style={{ display: "flex", alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <Typography align="left">Controls Confidentiality Rating: </Typography>
                <Typography align="right">{row.avgTotalC}</Typography>
            </div>
            <div style={{ display: "flex", alignItems: 'flex-end',justifyContent: 'space-between' }}>
                <Typography align="left">Technology Risk Level:</Typography>
                <Typography align="right">{row.avgPRR6.[0].rate}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Risk;