import axios from 'axios';

const API = axios.create({ baseURL: process.env.REACT_APP_API_SERVER })

const mail = JSON.parse(localStorage.getItem('profile'))
const user = mail?.result.email;

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
  });

export const fetchPosts = () => API.get('/posts');
export const createPost = (newPost) => API.post('/posts', newPost);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const getUsers = () => API.get('/user');
export const deleteUser = (id) => API.delete(`/user/${id}`);

export const fetchResults = () => API.get(`/result/${user}`);
export const createResult = (newResult) => API.post('/result', newResult);
export const updateResult = (id, updatedResult) => API.patch(`/result/${id}`, updatedResult);
export const deleteResult = () => API.delete(`/result/${user}`);

export const fetchAverage = (users) => API.get(`/avg/${users}`);