import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './category.css';

export function handleClick (event, param) {
  
  const cat = param;
    
  console.log(cat);
};

export default function Category() {
  const [isNavExpanded, setIsNavExpanded,] = useState(false);
  const location = useLocation();
  
  const url =location.pathname;
  const view ='/view';

  return (
    <nav className="categoryNav">
      <Typography className="url">
      {(() => {
        if (view===url) {
          return 'NewlyAdded';
        } else {
          return url;
        }
      })()}
      </Typography>
      <button
        type="button"
        className="files"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* icon from heroicons.com */}
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="white" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        
          <path stroke-linecap="round" stroke-linejoin="round" 
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
        </svg>
        
      </button>
      <div
        className={
          isNavExpanded ? 'categoryNav expanded' : 'categoryNav'
        }
      >
        <ul onClick={() => {setIsNavExpanded(!isNavExpanded);}}>
          <li>
            <NavLink to='/question/SensitivityRating'>Sensitivity Rating</NavLink>
          </li>
          <li>
            <NavLink to='/question/HostSystemControls'>Host System Controls</NavLink>
          </li>
          <li>
            <NavLink to="/question/NetworkCommunicationsControls">Network & Communications Controls</NavLink>
          </li>
          <li>
            <NavLink to="/question/ClientWorkstationControls">Client Workstation Controls</NavLink>
          </li>
          <li>
            <NavLink to="/question/ApplicationControls">Application (design & coding) Controls</NavLink>
          </li>
          <li>
            <NavLink to="/question/EnvironmentPhysicalSecurityControls">Environment and Physical Security Controls</NavLink>
          </li>
          <li>
            <NavLink to="/question/ApplicationContinuityControls">Application Continuity Controls</NavLink>
          </li>
          <li>
            <NavLink to="/question/BusinessProceduresContinuityControls">Business Procedures Continuity Controls</NavLink>
          </li>
          <li>
            <NavLink to="/question/AccessControls">Access Controls</NavLink>
          </li>
          <li>
            <NavLink to="/question/GeneralProceduresControls">General Procedures Controls</NavLink>
          </li>
          <li>
            <NavLink to="/question/PrivacyChecklistRating">Privacy Checklist Rating</NavLink>
          </li>
          <li>
            <NavLink to="/question/PrivacyRiskRating">Privacy Risk Rating</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
