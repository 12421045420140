import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginTop: 80,
    marginBottom: 30,
    color: '#073763'
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
    color: "#1F1424",
    fontFamily: 'Helvetica',
    fontWeight: 1,
    letterSpacing: 0.2
  },
  bold: {
    margin: 2,
    fontSize: 11,
    fontWeight: 500,
    textAlign: "justify",
    color: "#1F1424",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  logo: {
    width: '68px',
    height: '48px',
    marginLeft:'300px',
    marginTop: '-35px'
  },

  name: {
    marginLeft: 10,
    marginTop: "-50",
    fontSize: 10,
  },

  control: {
    fontSize: 12,
    margin: '0 auto',
    marginTop: '50px',
    marginBottom: '5px'
  },

  score: {
    fontSize: 16,
    margin: '0 auto',
    marginTop: '50px',
    marginBottom: '5px'
  },

  chart: {
    width: '410px',
    height: '320px',
    margin: '0 auto'
  },
  header: {
    fontSize: 8,
    marginBottom: 0,
    textAlign: "right",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: { 
    marginTop: 10,
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  },
  tableRisk: { 
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    borderTop: 'none'
  },  
  tableRow: { 
   // margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColControl: { 
    width: "40%",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCol: { 
    width: "15%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableColLegend: { 
    width: "85%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0, 
    textAlign: 'left'
  },

  tableColRisk: { 
    width: "30%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },

  tableColRiskHeader: { 
    width: "30%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 1 
  },

  tableColLevel: { 
    width: "15%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 1 
  },
  
  tableColGreen: { 
    width: "15%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    backgroundColor: '#00FF00'
  }, 

  tableColGrey: { 
    width: "15%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    backgroundColor: '#CCCCCC'
  }, 

  tableColYellow: { 
    width: "15%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    backgroundColor: '#FFFF00'
  }, 

  tableColRed: { 
    width: "15%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    backgroundColor: '#FF0000'
  }, 


  tableCell: { 
    marginLeft: "10", 
    marginTop: 5, 
    fontSize: 10,
    width: 'auto' 
  },

  tableCellRisk: { 
    marginLeft: "10", 
    marginTop: 5, 
    fontSize: 10,
    width: 'auto' 
  },

  tableCellNum: { 
    margin: "auto", 
    marginTop: 5, 
    fontSize: 10,
    width: 'auto' 
  },


  legend: {
    fontSize: 10,
    marginTop: 5
  },
  green: {
    fontSize: 10,
    backgroundColor: '#00FF00',
  },
  grey: {
    fontSize: 10,
    backgroundColor: '#CCCCCC',
  },
  yellow: {
    fontSize: 10,
    backgroundColor: '#FFFF00',
  },
  red: {
    fontSize: 10,
    backgroundColor: '#FF0000',
  }

});

export default style