import { FETCH_ALL, CREATE, UPDATE, DELETE, LIKE, } from '../constants/actionTypes';

import * as api from '../api/index.js';

export const getPosts = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(b,a) {
      return (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0);
   });
    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const createPost = (post) => async (dispatch) => {
  try {
    const { data } = await api.createPost(post);
    dispatch({ type: CREATE, payload: data });    
  } catch (error) {
    console.log(error.message);
  }
};

export const updatePost = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.updatePost(id, post);
    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const likePost = (id) => async (dispatch) => {
  try {
    const { data } = await api.likePost(id);
    dispatch({ type: LIKE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const deletePost = (id) => async (dispatch) => {
  try {
    await api.deletePost(id);
    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error.message);
  }
};

export const SensitivityRating = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Sensitivity Rating"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const HostSystemControls = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Host System Controls"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const NetworkCommunicationsControls = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Network & Communications Controls"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const ClientWorkstationControls = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Client Workstation Controls"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const ApplicationControls = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Application (design & coding) Controls"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const EnvironmentPhysicalSecurityControls = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Environment and Physical Security Controls"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const ApplicationContinuityControls = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Application Continuity Controls"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const BusinessProceduresContinuityControls = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Business Procedures Continuity Controls"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const AccessControls = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Access Controls"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const GeneralProceduresControls = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("General Procedures Controls"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const PrivacyChecklistRating = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Privacy Checklist Rating"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};

export const PrivacyRiskRating = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    data.sort(function(a,b) {
      return (a.questionNumber > b.questionNumber) ? 1 : ((b.questionNumber > a.questionNumber) ? -1 : 0);
   });
    const filter = data.filter(data=> data.category.includes("Privacy Risk Rating"))    
    dispatch({ type: FETCH_ALL, payload: filter });
  } catch (error) {
    console.log(error.message);
  }
};



