import { GET_USERS, UPDATE, DELETE_USER } from '../constants/actionTypes';

export default (users = [], action) => {
  switch (action.type) {
    case GET_USERS:
      return action.payload;
    case UPDATE:
      return users.map((users) => (users._id === action.payload._id ? action.payload : users));
    case DELETE_USER:
      return users.filter((users) => users._id !== action.payload);
    default:
      return users;
  }
};

