import {AVERAGE} from '../constants/actionTypes';

export default ( averages = [], action) => {
  switch (action.type) {
    case AVERAGE:
      return action.payload;
    default:
      return averages;
  }
};

