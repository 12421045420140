import React, {useEffect, useCallback } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useCurrentPng  } from "recharts-to-png";
import {
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer
} from 'recharts';
import './style.css'


const Chart = ({ avg }) => {

  const [getPng, { ref, isLoading }] = useCurrentPng();

  const handleSave = useCallback(async () => {
    const png = await getPng();
    if (png) {
      sessionStorage.setItem("imgData", png);
      sessionStorage.setItem("Controls", JSON.stringify(data)); 
      sessionStorage.setItem("Risks", JSON.stringify(riskData));      
    }
  }, [getPng]);

  useEffect(() => {
  setTimeout(() => {
      handleSave()
    }, 2000);
  },[getPng])

  const riskData = [
    {
      PRR1: avg.avgPRR1.rate,
      PRR2: avg.avgPRR2.rate,
      PRR3: avg.avgPRR3.rate,
      PRR4: avg.avgPRR4.rate,
      PRR5: avg.avgPRR5.[0].rate,
      PRR6: avg.avgPRR6.[0].rate,
      PRR7: avg.avgPRR7.[0].rate,
      PRR8: avg.avgPRR8.[0].rate,
      PrivacyRiskRating: avg.avgPRR.Avg,
      PrivacyChecklistRating: avg.avgPCR.Avg,
      ControlsConfidentialityRating: avg.avgTotalC,
      TechnologyRiskLevel: avg.avgPRR6.[0].rate ,

    }
  ]

  const data = [
    {
      name: 'Host System Controls',
      Average: avg.avgHsc.Avg,
      Confidentiality : avg.avgHscC.Avg,
      Integrity       : avg.avgHscI.Avg,
      Availability    : avg.avgHscA.Avg,
    },
    {
      name: 'Network & Communication Controls',
      Average: avg.avgNcc.Avg,
      Confidentiality : avg.avgNccC.Avg,
      Integrity       : avg.avgNccI.Avg,
      Availability    : avg.avgNccA.Avg,
    },
    {
      name: 'Client Workstation Controls',
      Average: avg.avgCwc.Avg,
      Confidentiality : avg.avgCwcC.Avg,
      Integrity       : avg.avgCwcI.Avg,
      Availability    : avg.avgCwcA.Avg,
    },
    {
      name: 'Application (Design & Coding) Controls',
      Average: avg.avgAdcc.Avg,
      Confidentiality : avg.avgAdccC.Avg,
      Integrity       : avg.avgAdccI.Avg,
      Availability    : avg.avgAdccA.Avg,
    },
    {
      name: 'Environment & Physical Security Controls',
      Average: avg.avgEpsc.Avg,
      Confidentiality : avg.avgEpscC.Avg,
      Integrity       : avg.avgEpscI.Avg,
      Availability    : avg.avgEpscA.Avg,
    },
    {
      name: 'Application Continuity Controls',
      Average: avg.avgAcc.Avg,
      Confidentiality : avg.avgAccC.Avg,
      Integrity       : avg.avgAccI.Avg,
      Availability    : avg.avgAccA.Avg,
    },
    {
      name: 'Access Controls',
      Average: avg.avgAc.Avg,
      Confidentiality : avg.avgAcC.Avg,
      Integrity       : avg.avgAcI.Avg,
      Availability    : avg.avgAcA.Avg,
    },
    {
      name: 'General Procedures Controls',
      Average: avg.avgGpc.Avg,
      Confidentiality : avg.avgGpcC.Avg,
      Integrity       : avg.avgGpcI.Avg,
      Availability    : avg.avgGpcA.Avg,
    },
    {
      name: 'Total Controls',
      Average: avg.avgTotal,
      Confidentiality : avg.avgTotalC,
      Integrity       : avg.avgTotalI,
      Availability    : avg.avgTotalA,
    },
    {
      name: 'Business Procedures Continuity Rating',
      Average: avg.avgBpcr.Avg,
      Confidentiality : avg.avgBpcrC.Avg,
      Integrity       : avg.avgBpcrI.Avg,
      Availability    : avg.avgBpcrA.Avg,
    },
  ];

    return (
      <Container maxWidth='sm'>
        <Typography align='center' variant='h6'>Controls Rating</Typography>
        <ResponsiveContainer width="100%" height={400}>

          <BarChart className='bg'
            ref={ref}
            data={data}
            layout="vertical"
            margin={{
              top: 5,
              right: 10,
              left: -65,
              bottom: 5,
            }}
          >

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type='number' />
            <YAxis tick={{fontSize: 10, color: 'gray'}}
                  width={200}
                  tickLine={true}
                  tickMargin={5} 
                  type='category' 
                  dataKey='name' />
            <Legend wrapperStyle={{fontSize: "11px"}} align="right"/>
            <ReferenceLine x={0} stroke="#000" />
            <Bar dataKey="Confidentiality" fill="#3366CC " />
            <Bar dataKey="Integrity"       fill="#DC3912" />
            <Bar dataKey="Availability"    fill="#FF9900" />
          </BarChart>
        </ResponsiveContainer>
      </Container>
    );
    
  }

  export default Chart

