import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Typography, Avatar, Button, Toolbar } from '@material-ui/core';
import { Link, useHistory, useLocation, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import * as actionType from '../../constants/actionTypes';

import './styles.css';
import logo from '../../images/CMSlogo.jpg';
import useStyles from './styles';

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const isAdmin = useRef(false);
  const mail = JSON.parse(localStorage.getItem('profile'))
  const admin = mail?.result.email;
  if (admin===process.env.REACT_APP_ADMIN){
    isAdmin.current = true;
  }

  const isLoading = useRef(false);

  const check = localStorage.getItem('profile');
    if (check!=null){
        isLoading.current = true;
      }

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    history.push('/auth');

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);

  return (
    <div>
    <AppBar className={classes.appBar} position="static" color="inherit">
    <div className={classes.brandContainer}>
          <Typography onClick={()=> window.open("https://www.cmsconsulting.ca/", "_blank")} className={classes.heading} variant="h2" >CMS Consulting</Typography>
          <img  className={classes.image} src={logo} alt="icon" height="60" />
          </div> 
        <Toolbar className={classes.toolbar}>
         
        {user?.result ? (
          <div className={classes.profile}>
            <Avatar className={classes.purple} alt={user?.result.name} src={user?.result.picture}>{user?.result.picture}</Avatar>
            <Typography className={classes.userName} variant="h6">{user?.result.name}</Typography>
            <Button variant="contained" className={classes.logout} color="secondary" onClick={logout}>Logout</Button>
          </div>
        ) : (
          <Button className={classes.signin} component={Link} to="/auth" variant="contained" color="primary">Sign In</Button>
        )}
      </Toolbar>
      
    </AppBar>
    <div style={{display: user ? 'block' : 'none'}}>

    <nav className="navigation">
      <a href="/" className="brand-name">
        Security Check
      </a>
      <button
        type="button"
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* icon from heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'
        }
        
      >
        <ul onClick={() => {setIsNavExpanded(!isNavExpanded);}}>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/survey">Survey</NavLink>
          </li>
          <li>
            <NavLink to="/summary" onClick={() =>{sessionStorage.clear()}}>Summary</NavLink>
          </li>
          <li className={isAdmin.current? 'display' : 'displayNone'}>
            <NavLink to="/create">Create</NavLink>
          </li>
          <li className={isAdmin.current? 'display' : 'displayNone'}>
            <NavLink to="/question">Question</NavLink>
          </li>
          <li className={isAdmin.current? 'display' : 'displayNone'}>
            <NavLink to="/user" onClick={() =>{sessionStorage.clear()}}>User</NavLink>
          </li>
        </ul>
      </div>
    </nav>
    </div>
    </div>
  );
}
