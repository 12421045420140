import React, { useRef } from 'react'
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import './styles.css';

const Welcome = () => {
  const check = window.localStorage.getItem('profile');
  const isLogin = useRef(false);

  if (check!=null){
    isLogin.current = true;
  }

  return (
    
    <Container className="intro-logo">
      <h1>Welcome to CMS Consulting!</h1>
      <h3>Take a quick survey for security check.</h3>
      <img
        src=""
        className=""
        alt=""
      />
      <div className="intro-button">
        <Link to={isLogin.current? '/survey' : '/auth' }>Take the Survey</Link>
      </div>
    
      <div className="company-icons">
        <span className="company-icons__item">
          <i className="fab fa-apple" />
          <a href="https://www.cmsconsulting.ca/">CMS Consulting Website</a>
        </span>
        <span className="company-icons__item">
          <i className="fab fa-google-play" />
          <a href="https://www.linkedin.com/company/cms-consulting-inc._2">LinkedIn</a>
        </span>
        <span className="company-icons__item">
          <i  className="fab fa-windows" />
          <a href="https://www.cmsconsulting.ca/contact-us/">Contact</a>
        </span>
      </div>

    </Container>
  )
}

export default Welcome
