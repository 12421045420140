import React from 'react'
import { TableBody, TableCell, TableRow, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SummaryTable from './SummaryTable/Table'
import Risk from './SummaryTable/Risk'
import './styles.css';

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const Result = () => {   
  const email = JSON.parse(sessionStorage.getItem('email'))
  const name = email?.name
  const history = useHistory()
  const pdfPage = async event => {
    await delay(1600);
    history.push('/pdf');
  }

  const clear = () => {
    history.push('/user');
    sessionStorage.clear();
  };
  
  return (
    
    <div className="results">
      
      <Button color='primary' align='left' variant='contained' onClick={() => {clear()}}><ArrowBackIosIcon/></Button>
      <h1>{name}<br/>Assessment Self-Report Results</h1>
      <div className="download">
        <Button color='primary' variant='contained' onClick={pdfPage}>View in PDF</Button>
      </div>
      <Typography variant='h6' align='right'></Typography>
      <SummaryTable/>  
      <div className="summary">
        <Typography className="text">Cells with a Yellow background indicate that the corresponding control is weak and it is suggested that mitigation techniques should be implemented to reduce or restrict any negative exposures.</Typography>
      </div>

      <div className="title">
        <Typography variant='h4'>Personal Information Risk Summary</Typography>
        <Typography>The table below is a summary of the privacy risk levels, likelihood of occurrence and mitigation measures that should be taken.</Typography>
        <div className="risk">
          <Risk/> 
        </div>
      </div>
      <TableBody style={{backgroundColor:'#fff', display: 'block', marginBottom: '1rem'}} align="left">
          <TableRow>
            <TableCell style={{backgroundColor:'gray'}} component="th" scope="row">Low:</TableCell>
            <TableCell align="left">There is a possibility that the risk will materialize but there are mitigating factors.</TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{backgroundColor:'yellow'}} component="th" scope="row">Medium:</TableCell>
            <TableCell align="left">There is a strong possibility that the risk will materialize if no corrective measures are taken.</TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{backgroundColor:'red'}} component="th" scope="row">High:</TableCell>
            <TableCell align="left">There is a near certainty that the risk will materialize if no corrective measures are taken.  Perform a Privacy Impact Assessment (PIA) immediately.</TableCell>
          </TableRow>
        </TableBody>
    </div>   
  )
}

export default Result
