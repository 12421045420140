import React, { useRef } from 'react';
import { Text, View,} from "@react-pdf/renderer";
import styles from './styles'

const Table = () => {
    const checkData = useRef(true)
    const controls = JSON.parse(sessionStorage.getItem("Controls")) || '';
    if(controls==null){
        checkData.current = false
        console.log("control is null!")
    }

    const setColor = (value) => {
        let style = styles.tableCol
        if (value!=null){
        switch (true) {
            case (value >= 3.75):
                style = styles.tableColGreen
                break;
            case (value >= 2.5):
                style = styles.tableColGrey
                break;
            case (value >= 1.25):
                style = styles.tableColYellow
                break;
            case (value < 1.25):
                style = styles.tableColRed
                break;
            default:
                break
        }}
        
        return style
    }


    return (
        !controls ? <Text >Nodata</Text> : (
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Controls</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCellNum}>Average</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCellNum}>Confidentiality</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCellNum}>Integrity</Text> 
                </View>
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCellNum}>Availability</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Host System Controls</Text> 
                </View> 
                <View style={setColor(controls.[0].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[0].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[0].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[0].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[0].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[0].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[0].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[0].Availability : ''}</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Network & Communication Controls</Text> 
                </View> 
                <View style={setColor(controls.[1].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[1].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[1].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[1].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[1].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[1].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[1].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[1].Availability : ''}</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Client Workstation Controls</Text> 
                </View> 
                <View style={setColor(controls.[2].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[2].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[2].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[2].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[2].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[2].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[2].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[2].Availability : ''}</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Application (Design & Coding) Controls</Text> 
                </View> 
                <View style={setColor(controls.[3].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[3].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[3].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[3].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[3].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[3].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[3].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[3].Availability : ''}</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Environment & Physical Security Controls</Text> 
                </View> 
                <View style={setColor(controls.[4].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[4].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[4].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[4].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[4].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[4].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[4].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[4].Availability : ''}</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Application Continuity Controls</Text> 
                </View> 
                <View style={setColor(controls.[5].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[5].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[5].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[5].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[5].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[5].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[5].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[5].Availability : ''}</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Access Controls</Text> 
                </View> 
                <View style={setColor(controls.[6].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[6].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[6].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[6].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[6].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[6].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[6].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[6].Availability : ''}</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>General Procedures Controls</Text> 
                </View> 
                <View style={setColor(controls.[7].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[7].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[7].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[7].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[7].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[7].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[7].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[7].Availability : ''}</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Total Controls</Text> 
                </View> 
                <View style={setColor(controls.[8].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[8].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[8].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[8].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[8].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[8].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[8].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[8].Availability : ''}</Text> 
                </View>  
            </View>
            <View style={styles.tableRow}> 
                <View style={styles.tableColControl}> 
                    <Text style={styles.tableCell}>Business Procedures Continuity Rating</Text> 
                </View> 
                <View style={setColor(controls.[9].Average)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[9].Average : ''}</Text> 
                </View> 
                <View style={setColor(controls.[9].Confidentiality)}>
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[9].Confidentiality : ''}</Text> 
                </View>
                <View style={setColor(controls.[9].Integrity)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[9].Integrity : ''}</Text> 
                </View>
                <View style={setColor(controls.[9].Availability)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? controls.[9].Availability : ''}</Text> 
                </View>  
            </View>

      </View>
        )
    );
 };
    
export default Table;