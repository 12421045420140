import React, { useRef } from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import Form from './components/Form/SurveyForms/Form'; 
import { Container} from '@material-ui/core';
import View from './View';
import Create from './Create';
import Welcome from './components/Pages/Welcome';
import Result from './components/Pages/Result';
import User from './components/Pages/User';
import Summary from './components/Pages/Summary';
import SummaryAdmin from './components/Pages/SummaryAdmin';
import Thank from './components/Pages/Thank';
import Navbar from './components/Navbar/Navbar';
import Chart from './components/Chart/Chart';
import Auth from './components/Auth/Auth';
import PDFView from './components/Reports/PDFView';
import {GoogleOAuthProvider} from '@react-oauth/google';

import "./styles.css";

require('dotenv').config()

const App = () => {
  const isLogin = useRef(false);
  const isAdmin = useRef(false);
  const check = localStorage.getItem('profile');
  const mail = JSON.parse(localStorage.getItem('profile'))
  const user = mail?.result.email;
  if (check!=null){
    isLogin.current = true;
  }
  if (user===process.env.REACT_APP_ADMIN){
    isAdmin.current = true;
  }
  
  return (
    <GoogleOAuthProvider clientId = {process.env.REACT_APP_GOOGLE_AUTH_API}>
    
      <HashRouter>  
     
        <Container maxWidth="lg">

          <Navbar/>
          
          
            <Switch>
              
              <Route path="/" exact component={Welcome}/>
              <Route path='/survey' exact component={ Form } />
              <Route path="/summary" exact component={isLogin.current? Summary : Auth}/>
              <Route path="/summary_admin" exact component={isLogin.current? SummaryAdmin : Auth}/>
              <Route path="/create" exact component={Create}/>

              <Route path='/question' component={View}/>  
              <Route path="/auth" exact component={Auth}/>
              <Route path="/chart" exact component={Chart}/>
              
              <Route path="/thanks" exact component={isLogin.current? Thank : Auth}/>
              <Route path="/result" exact component={isLogin.current? Result : Welcome}/>
              <Route path="/user" exact component={isLogin.current? User : Welcome}/>
              
              <Route path="/pdf">
                  <PDFView />
              </Route>

            </Switch>
          
          
        </Container> 
        
      </HashRouter>  
      

    </GoogleOAuthProvider>
    
  );
};

export default App;
