import React from 'react'
import { Container } from '@material-ui/core';
import './styles.css';
import Result from './Result'
import { useDispatch } from 'react-redux';
import { deleteResult } from '../../actions/results';
import MyTimer from './Timer/Timer'

const Thank = () => {
  const dispatch = useDispatch();

  dispatch(deleteResult())
 
  const time = new Date();
  time.setSeconds(time.getSeconds() + 30);
  
  

  return (
    <Container className="intro-logo">
      <h1>Thank you for taking the survey!</h1>
      
      <MyTimer expiryTimestamp={time} />
      
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Result/>
    </Container>
  )
}

export default Thank
