import React from 'react';
import { Card, CardActions, CardContent, Button, Typography, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { deletePost } from '../../../actions/posts';
import useStyles from './styles';


const Post = ({ post, setCurrentId }) => {
  
  const dispatch = useDispatch();
  const classes = useStyles();
  
  return (
    <div>
      <Card className={classes.card}>
        <Typography variant="body2" color="textSecondary" component="p">{post.Confidentiality}{post.Integrity}{post.Availability}{post.Privacy}</Typography>
        <Typography className={classes.title} gutterBottom variant="h5" component="h2">{post.questionNumber}). {post.question}</Typography>

        <Container className={classes.ratings}>
          <FormControl >
            <FormLabel id="demo-radio-buttons-group-label" />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="undefined"
              name="radio-buttons-group"
            >
              <FormControlLabel value="rating1" control={<Radio />} label={post.rating1} />
              <FormControlLabel value="rating2" control={<Radio />} label={post.rating2} />
              <FormControlLabel value="rating3" control={<Radio />} label={post.rating3} />
              <FormControlLabel value="rating4" control={<Radio />} label={post.rating4} />
              <FormControlLabel value="rating5" control={<Radio />} label={post.rating5} />
            </RadioGroup>
          </FormControl>
        </Container>

        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">Tips: {post.help}</Typography>
          <Typography variant="body2" color="textPrimary" component="p">Category: {post.category}</Typography>
          <Typography variant="body2" color="textPrimary" component="p">Confidentiality: {post.confidentiality ? 'True' : 'False' }</Typography>
          <Typography variant="body2" color="textPrimary" component="p">Integrity: {post.integrity ? 'True' : 'False'}</Typography>
          <Typography variant="body2" color="textPrimary" component="p">Availability: {post.availability ? 'True' : 'False'}</Typography>
          <Typography variant="body2" color="textPrimary" component="p">Privacy: {post.privacy ? 'True' : 'False'}</Typography>
        </CardContent>
        
        <CardActions className={classes.cardActions}>
          <Button size="small" color="primary" onClick={() => {
            setCurrentId(post._id)
           }}> Edit</Button>
          <Button size="small" color="primary" onClick={() => dispatch(deletePost(post._id))}><DeleteIcon fontSize="small" /> Delete</Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default Post;
