import React, { useState, useEffect} from 'react';
import { Grid} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation  } from 'react-router-dom'
import "./styles.css";
import Posts from './components/Posts/Posts';
import Edit from './components/Form/Edit';
import Category from './components/Navbar/Category';
import {getPosts,
        SensitivityRating, 
        HostSystemControls, 
        NetworkCommunicationsControls, 
        ClientWorkstationControls, 
        ApplicationControls, 
        EnvironmentPhysicalSecurityControls, 
        ApplicationContinuityControls, 
        BusinessProceduresContinuityControls, 
        AccessControls, GeneralProceduresControls, 
        PrivacyChecklistRating, PrivacyRiskRating     
} from './actions/posts';
import useStyles from './styles';

const View = () => {
  const [currentId, setCurrentId] = useState(0);
  const history = useHistory()
  const location = useLocation();
  const url = location.pathname;
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    return history.listen((location) => { 
      console.log(`You changed the page to: ${location.pathname}`) 
    }) },[history]) 

    if(url ==="/question/SensitivityRating"){
      dispatch(SensitivityRating());      
    }
    else if(url ==="/question/HostSystemControls"){
      dispatch(HostSystemControls());      
    }
    else if(url ==="/question/NetworkCommunicationsControls"){
      dispatch(NetworkCommunicationsControls());      
    }
    else if(url ==="/question/ClientWorkstationControls"){
      dispatch(ClientWorkstationControls());      
    }
    else if(url ==="/question/ApplicationControls"){
      dispatch(ApplicationControls());      
    }
    else if(url ==="/question/EnvironmentPhysicalSecurityControls"){
      dispatch(EnvironmentPhysicalSecurityControls());      
    }
    else if(url ==="/question/ApplicationContinuityControls"){
      dispatch(ApplicationContinuityControls());      
    }
    else if(url ==="/question/BusinessProceduresContinuityControls"){
      dispatch(BusinessProceduresContinuityControls());      
    }
    else if(url ==="/question/AccessControls"){
      dispatch(AccessControls());      
    }
    else if(url ==="/question/GeneralProceduresControls"){
      dispatch(GeneralProceduresControls());      
    }
    else if(url ==="/question/PrivacyChecklistRating"){
      dispatch(PrivacyChecklistRating());      
    }
    else if(url ==="/question/PrivacyRiskRating"){
      dispatch(PrivacyRiskRating());      
    }
    else{
      dispatch(getPosts());
    }

  return (
    <div>
      <Category/>
      <div className="content">
        <Grid container justify="space-between" alignItems="stretch" spacing={2}>
          <Grid item xs={12} sm={6}>
              <Posts setCurrentId={setCurrentId} />
          </Grid>
            <Grid className={classes.edit} item xs={12} sm={6}>
              <Edit currentId={currentId} setCurrentId={setCurrentId} />
            </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default View;
