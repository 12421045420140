import React, { useRef } from 'react';
import { Page, Text, Image, Document, View } from "@react-pdf/renderer";
import logo from "../../images/CMSlogo.jpg";
import styles from './styles'
import Table from './Table'
import Risk from './Risk';
import RiskLegend from './RiskLegend';

const PDFFile = () => {
  
  const chart = sessionStorage.getItem('imgData');
  const checkData = useRef(true)
    const risks = JSON.parse(sessionStorage.getItem("Risks")) || '';
    const email = JSON.parse(sessionStorage.getItem('email')) || '';
    const user = JSON.parse(localStorage.getItem('profile')) || '' ;
    const name = email?.name || user?.result.name;
    if(risks==null){
        checkData.current = false
        console.log("Risk is null!")
    }

  return (
    !risks ? <Text >Nodata</Text> : (
    <Document>
      <Page size="A4" style={styles.body}>
        
        <Text style={styles.header} fixed>An Integrated Technology Solutions Provider<br></br></Text>
        <Text style={styles.header} fixed>tel no: 1-888-346-7242</Text>
        <Text style={styles.header} fixed>www.cmsconsulting.ca</Text>
        <Image style={styles.logo} src={logo} fixed/>
        <Text style={styles.name}>Name:&nbsp;{name}</Text>
        <Text style={styles.title}>
            CMS Consulting Online IT Security Assessment Self-Report Results
        </Text>
        
        <Text style={styles.text}>
            The online security assessment took the answers provided and found two areas with weak controls: network and communication infrastructure, and client workstations.
        </Text>
        <Text style={styles.control}>
          Controls Rating
        </Text>
        <Image style={styles.chart} src={chart}/>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
      <Page size="A4" style={styles.body}>
        
        <Text style={styles.header} fixed>An Integrated Technology Solutions Provider<br></br></Text>
        <Text style={styles.header} fixed>tel no: 1-888-49-3885</Text>
        <Text style={styles.header} fixed>www.cmsconsulting.ca</Text>
        <Image style={styles.logo} src={logo} fixed/>
        <Text style={styles.score}>
          Score Sheet
          
        </Text>
        <View>
          <Table/>
        </View>
        <Text style={styles.legend}>Legend: <Text style={styles.green}>Strong</Text>, <Text style={styles.grey}>Balanced</Text>, <Text style={styles.yellow}>Weak</Text>, <Text style={styles.red}>Alert</Text></Text>
        <Text style={styles.text}>Cells with a Yellow background indicate that the corresponding control is weak and it is suggested that mitigation techniques should be implemented to reduce or restrict any negative exposures.</Text>
        <Text style={styles.text}>Network and communication confidentially is weakened due to communications between this application and external parties being done without the use any security protection.</Text>
        <Text style={styles.text}>Client workstation confidentiality is weakened due to no protection being used on the client workstation to protect against malicious software.</Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
      <Page size="A4" style={styles.body}>
        
        <Text style={styles.header} fixed>An Integrated Technology Solutions Provider<br></br></Text>
        <Text style={styles.header} fixed>tel no: 1-888-49-3885</Text>
        <Text style={styles.header} fixed>www.cmsconsulting.ca</Text>
        <Image style={styles.logo} src={logo} fixed/>
        <Text style={styles.score}>Personal Information Risk Summary</Text>
        <Text style={styles.text}>The table below is a summary of the privacy risk levels, likelihood of occurrence and mitigation measures that should be taken.</Text>
        <View>
          <Risk/>
        </View>
        <Text style={styles.text}>Legend: <Text style={styles.grey}>Low</Text>, <Text style={styles.yellow}>Medium</Text>, <Text style={styles.red}>High</Text></Text>
        <Text style={styles.bold}>Privacy Risk Rating:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{risks.[0].PrivacyRiskRating}</Text>
        <Text style={styles.bold}>Privacy Checklist Rating:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{risks.[0].PrivacyChecklistRating}%</Text>
        <Text style={styles.bold}>Controls Confidentiality Rating:&nbsp;&nbsp;&nbsp; {risks.[0].ControlsConfidentialityRating}</Text>
        <Text style={styles.bold}>Technology Risk Level:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{risks.[0].TechnologyRiskLevel}</Text>
        <RiskLegend/>
        <Text style={styles.text}>The program's use of personal information includes external administrative purposes, and affects all individuals.  Therefore there is a near certainty that the risk will materialize if no corrective measures are taken.</Text>
        <Text style={styles.text}>Recommendation: perform a privacy impact assessment and relevant corrective measures.</Text>
        <Text style={styles.text}>The type of personal information involved and the context in which it is used renders the information more sensitive than it would otherwise be. Social Insurance Number, medical, financial or other sensitive personal information or the context surrounding the personal information is sensitive; personal information of minors or of legally incompetent individuals or involving a representative acting on behalf of the individual.  Therefore there is a strong possibility that the risk will materialize if no corrective measures are taken.</Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
      <Page size="A4" style={styles.body}>
        <Text style={styles.header} fixed>An Integrated Technology Solutions Provider<br></br></Text>
        <Text style={styles.header} fixed>tel no: 1-888-49-3885</Text>
        <Text style={styles.header} fixed>www.cmsconsulting.ca</Text>
        <Image style={styles.logo} src={logo} fixed/>
        <Text style={styles.text}></Text>
        <Text style={styles.text}></Text>
        <Text style={styles.text}>With all the tables and charts presented above especially on the Risk Summary, it is best to have your business process examined thoroughly with a reliable IT company. A healthy risk-free company in terms of cyber crime attacks increases productivity and minimizes cost over time.</Text>
        <Text style={styles.text}>CMS Consulting, a growing IT company, offers affordable IT Security, Document and Records Management to different industries across North America. We have created the web assessment application to quickly determine the status of your business processes. Contact us today through +1.888.346.7242 or visit http://cmsconsulting.ca/ for more details.</Text>
        <Text style={styles.text}>With the desire to incorporate convenience in the innovative world of Information Technology, CMS Consulting Inc. has brought ease and accessibility to its potential and existing clients by providing a simple web based IT Security Assessment.</Text>
        <Text style={styles.text}>The web application is comprised of nine categories with different kinds of questions that will enable to identify the possible risk in every business process. </Text>
        <Text style={styles.text}>To emphasize the importance of IT Security and how greatly the web application helps every company in assessing risks, we have indicated a Review Table below in a detailed format with color codings. These are the results from different businesses that answered our survey. Moreover, the figures in Red for all Total Controls fields show that significant risk exists in the current application implementation. Departments must apply appropriate mitigation techniques to significantly reduce or eliminate negative consequences from these risks.</Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
    )
  );
};

export default PDFFile;