import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import './styles.css'
 
export default function General() {

  const rows = []
  for (let i = 0; i < localStorage.length; i++) {
    let storedValue = localStorage.key(i);
    let result = storedValue.includes("General");
    if (result) {
      const list = JSON.parse(localStorage.getItem(storedValue))
      const data = {
        question: list.questionNumber,
        comment: list.comment,
        rate: list.rate
      };
      rows.push(data)
    }
  }
  
  rows.sort((a, b) => (a.question < b.question ? -1 : 1));

  return (
    <TableContainer component={Paper} className="container" >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>General Procedures Controls</TableCell>
            <TableCell align="right">Ratings</TableCell>
            <TableCell align="right">Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.question}
              </TableCell>
              <TableCell align="right">{row.rate}</TableCell>
              <TableCell align="right">{row.comment}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
