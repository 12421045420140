import React from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import PDF from "./PDF"
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';

const PDFView = () => {
    const history = useHistory()
    const clear = () => {
        history.goBack();
      };
    return(
        <div>
        <Button color='primary' align='left' variant='contained' onClick={() => {clear()}}><ArrowBackIosIcon/></Button>
        <PDFViewer className="pdf">
            <PDF />
        </PDFViewer>
        </div>
    )
}

export default PDFView