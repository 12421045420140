import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, deleteUser} from '../../actions/user';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog , DialogActions ,DialogContent,DialogContentText, DialogTitle   } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import './styles.css';

const User = () => {     
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <div className="user">
      <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell ><b>Users</b></TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Results</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">{user.name}</TableCell>
                <TableCell align="right">{user.email}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => {const newUser = {['name']: user.name,['email']: user.email};
                          sessionStorage.setItem("email", JSON.stringify(newUser));

                        }}
                        component={Link} to="/summary_admin"
                    >View
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <Button size="small" color="primary" onClick={handleClickOpen} >
                    <DeleteIcon fontSize="small" />
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ opacity: 0.6,  backgroundColor: 'none'}}
                    
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Are you sure you want to delete this user?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        To delete this user select DELETE button. Cancel if you don't want. 
                        Deleting user will only delete their log in credentials, the survey results still remain in database.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>CANCEL</Button>
                      <Button onClick={() => {dispatch(deleteUser(user._id));setOpen(false);}} autoFocus>
                        DELETE
                      </Button>
                    </DialogActions>
                  </Dialog>
                </TableCell>
            </TableRow>
             ))}
        </TableBody>
        
      </Table>
    </TableContainer>
    </div>
    )
  }
  export default User