import React, { useState, useEffect } from 'react';
import { Container, Grid, TextField, Button, Typography, Paper, FormControl, InputLabel, NativeSelect, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles';
import { createPost, updatePost } from '../../actions/posts';

const Form = ({ currentId, setCurrentId }) => {
  const [postData, setPostData] = useState({ question: '', rating1: '', rating2: '', rating3: '', rating4: '', rating5: '', help: '', category: '', questionNumber: '', confidentiality: false, integrity: false, availability: false, privacy: false });
  const post = useSelector((state) => (currentId ? state.posts.find((message) => message._id === currentId) : null));
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (post) setPostData(post);
  }, [post]);

  const clear = () => {
    setCurrentId(0);
    setPostData({ question: '', rating1: '', rating2: '', rating3: '', rating4: '', rating5: '', help: '', category: '', questionNumber: '', confidentiality: false, integrity: false, availability: false, privacy: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentId === 0) {
      dispatch(createPost(postData));
      console.log('The data is save!');
      alert("The question has been added to the database!")
      clear();
    } else {
      dispatch(updatePost(currentId, postData));
      clear();
    }
  };

  return (
    <Paper className={classes.paper}>
      <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
        <Typography className={classes.title} variant="h4">{currentId ? `Editing Question #${post.questionNumber} of ${post.category}` : 'Create Questions'}</Typography>
        <Container>
          <Grid container justify="space-between" alignItems="stretch" spacing={2}>

            <Grid item xs={12} sm={4}>
              <TextField name="questionNumber" variant="outlined" label="Question Number" fullWidth value={postData.questionNumber} onChange={(e) => setPostData({ ...postData, questionNumber: e.target.value })} />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Category
                </InputLabel>
                <NativeSelect
                  value={postData.category}
                  inputProps={{
                    name: 'category',
                    id: 'uncontrolled-native',
                  }}
                  onChange={(e) => setPostData({ ...postData, category: e.target.value })}
                >
                  <option> </option>
                  <option value="Sensitivity Rating">Sensitivity Rating</option>
                  <option value="Host System Controls">Host System Controls</option>
                  <option value="Network & Communications Controls">Network & Communications Controls</option>
                  <option value="Client Workstation Controls">Client Workstation Controls</option>
                  <option value="Application (design & coding) Controls">Application (design & coding) Controls</option>
                  <option value="Environment and Physical Security Controls">Environment and Physical Security Controls</option>
                  <option value="Application Continuity Controls">Application Continuity Controls</option>
                  <option value="Business Procedures Continuity Controls">Business Procedures Continuity Controls</option>
                  <option value="Access Controls">Access Controls</option>
                  <option value="General Procedures Controls">General Procedures Controls</option>
                  <option value="Privacy Checklist Rating">Privacy Checklist Rating</option>
                  <option value="Privacy Risk Rating">Privacy Risk Rating</option>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={postData.confidentiality} onChange={(e) => setPostData({ ...postData, confidentiality: e.target.checked }) }  />} label="Confidentiality" />
                
                <FormControlLabel control={<Checkbox checked={postData.integrity} onChange={(e) => setPostData({ ...postData, integrity: e.target.checked })} />} label="Integrity" />
                <FormControlLabel control={<Checkbox checked={postData.availability} onChange={(e) => setPostData({ ...postData, availability: e.target.checked })} />} label="Availability" />
                <FormControlLabel control={<Checkbox checked={postData.privacy} onChange={(e) => setPostData({ ...postData, privacy: e.target.checked })} />} label="Privacy" />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container justify="space-between" alignItems="stretch" spacing={1}>

            <TextField name="question" variant="outlined" label="Question" fullWidth value={postData.question} onChange={(e) => setPostData({ ...postData, question: e.target.value })} />

            <TextField name="rating1" variant="outlined" label="Rating 1" fullWidth value={postData.rating1} onChange={(e) => setPostData({ ...postData, rating1: e.target.value })} />
            <TextField name="rating2" variant="outlined" label="Rating 2" fullWidth value={postData.rating2} onChange={(e) => setPostData({ ...postData, rating2: e.target.value })} />
            <TextField name="rating3" variant="outlined" label="Rating 3" fullWidth value={postData.rating3} onChange={(e) => setPostData({ ...postData, rating3: e.target.value })} />
            <TextField name="rating4" variant="outlined" label="Rating 4" fullWidth value={postData.rating4} onChange={(e) => setPostData({ ...postData, rating4: e.target.value })} />
            <TextField name="rating5" variant="outlined" label="Rating 5" fullWidth value={postData.rating5} onChange={(e) => setPostData({ ...postData, rating5: e.target.value })} />

            <TextField name="help" variant="outlined" label="Help" fullWidth multiline rows={4} value={postData.help} onChange={(e) => setPostData({ ...postData, help: e.target.value })} />
          </Grid>
        </Container>

        <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="save">Submit</Button>
        <Button className={classes.buttonClear} variant="contained" color="secondary" size="small" onClick={clear}>Clear</Button>
      </form>
    </Paper>
  );
};

export default Form;
