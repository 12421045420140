import { RESULT, CREATERESULT, UPDATERESULT, DELETE, LIKE, FILTER } from '../constants/actionTypes';

export default (results = [], action) => {
  switch (action.type) {
    case RESULT:
      return action.payload;
    case LIKE:
      return results.map((result) => (result._id === action.payload._id ? action.payload : result));
    case CREATERESULT:
      return [...results, action.payload];
    case UPDATERESULT:
      return results.map((result) => (result._id === action.payload._id ? action.payload : result));
    case DELETE:
      return results.filter((result) => result._id !== action.payload);
    case FILTER:
        return results.filter((result) => result._id === action.payload);
    default:
      return results;
  }
};

