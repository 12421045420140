import {AVERAGE} from '../constants/actionTypes';

import * as api from '../api/index.js';



export const getAverage = () => async (dispatch) => {
  const mail = JSON.parse(localStorage.getItem('profile'))
  const email = JSON.parse(sessionStorage.getItem('email'))
  const users = email?.email || mail?.result.email;
    try {
      const { data } = await api.fetchAverage(users);
      dispatch({ type: AVERAGE, payload: data });
    
    } catch (error) {
      console.log(error.message);
    }
  };