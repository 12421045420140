import React from "react";
import "./styles.css";

function End({ formData, setFormData }) {
  return (
    <div className="personal-info-container">
      <h1>Survey is Completed! <br></br> Press Submit Now!</h1>
    </div>
  );
}

export default End;