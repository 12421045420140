import React from 'react'
import { Table, TableBody, TableCell, TableRow, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SummaryTable from './SummaryTable/Table'
import Risk from './SummaryTable/Risk'
import { getResult} from '../../actions/results';
import './styles.css';

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const Result = () => {   
  const dispatch = useDispatch();
  const history = useHistory()
  dispatch(getResult());
  const pdfPage = async event => {
    await delay(1600);
    history.push('/pdf');
  }

  return (
    
    <div className="results"> 
      <h1>CMS Consulting Online IT Security Assessment Self-Report Results</h1>
      <div className="download">
        <Button color='primary' variant='contained' onClick={pdfPage}>View in PDF</Button>
      </div>
      <SummaryTable/>  
      <div className="summary">
        <Typography className="text">Cells with a Yellow background indicate that the corresponding control is weak and it is suggested that mitigation techniques should be implemented to reduce or restrict any negative exposures.</Typography>
      </div>

      <div className="title">
        <Typography variant='h4'>Personal Information Risk Summary</Typography>
        <Typography>The table below is a summary of the privacy risk levels, likelihood of occurrence and mitigation measures that should be taken.</Typography>
        <div className="risk">
          <Risk/> 
        </div>
      </div>
      <Table>
        <TableBody style={{backgroundColor:'#fff', display: 'block', marginBottom: '1rem'}} align="left">
            <TableRow>
              <TableCell style={{backgroundColor:'gray'}} component="th" scope="row">Low:</TableCell>
              <TableCell align="left">There is a possibility that the risk will materialize but there are mitigating factors.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{backgroundColor:'yellow'}} component="th" scope="row">Medium:</TableCell>
              <TableCell align="left">There is a strong possibility that the risk will materialize if no corrective measures are taken.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{backgroundColor:'red'}} component="th" scope="row">High:</TableCell>
              <TableCell align="left">There is a near certainty that the risk will materialize if no corrective measures are taken.  Perform a Privacy Impact Assessment (PIA) immediately.</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </div>   
  )
}

export default Result
