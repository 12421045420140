import React from 'react'
import './styles.css';
import SensitivityTable from './Table/Sensitivity'
import Host from './Table/Host'
import Network from './Table/Network'
import Client from './Table/Client'
import ApplicationDesign from './Table/ApplicationDesign'
import Environment from './Table/Environment'
import AppConCon from './Table/AppConCon'
import Business from './Table/Business'
import Access from './Table/Access'
import General from './Table/General'
import PrivacyChecklist from './Table/PrivacyChecklist'
import PrivacyRisk from './Table/PrivacyRisk'

const Result = () => {       

  return (

    <div className="results">
      <SensitivityTable/>    
      <Host/> 
      <Network/>
      <Client/> 
      <ApplicationDesign/>
      <Environment/>
      <AppConCon/>
      <Business/>
      <Access/>
      <General/>
      <PrivacyChecklist/>
      <PrivacyRisk/>
    </div>
  )
}

export default Result
