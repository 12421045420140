import React, { useRef } from 'react';

import {  Text, View } from "@react-pdf/renderer";
import styles from './styles'

const Table = () => {
    const checkData = useRef(true)
    const risks = JSON.parse(sessionStorage.getItem("Risks")) || '';
    if(risks==null){
        checkData.current = false
        console.log("Risk is null!")
    }

    const setColor = (value) => {
        let style = styles.tableCol
        if (value!=null){
        switch (true) {

            case (value === 5 ):
                style = styles.tableColGrey
                break;
            case (value <= 2):
                style = styles.tableColRed
                break; 
            case (value <= 4):
                style = styles.tableColYellow
                break;

            default:
                break
        }}
    
        return style
    }

    return (
        !risks ? <Text >Nodata</Text> : (
        <View style={styles.tableRisk}> 
            <View style={styles.tableRow}> 
                <View style={styles.tableColRiskHeader}> 
                    <Text style={styles.tableCell}>Risk</Text> 
                </View> 
                <View style={styles.tableColLevel}> 
                    <Text style={styles.tableCellNum}>Level</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColRisk}> 
                    <Text style={styles.tableCell}>Type of Application</Text> 
                </View> 
                <View style={setColor(risks.[0].PRR1)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? risks.[0].PRR1 : ''}</Text> 
                </View> 
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColRisk}> 
                    <Text style={styles.tableCell}>Type of Information</Text> 
                </View> 
                <View style={setColor(risks.[0].PRR2)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? risks.[0].PRR2 : ''}</Text> 
                </View> 
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColRisk}> 
                    <Text style={styles.tableCell}>Parties Involved</Text> 
                </View> 
                <View style={setColor(risks.[0].PRR3)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? risks.[0].PRR3 : ''}</Text> 
                </View> 
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColRisk}> 
                    <Text style={styles.tableCell}>Duration of Use</Text> 
                </View> 
                <View style={setColor(risks.[0].PRR4)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? risks.[0].PRR4 : ''}</Text> 
                </View> 
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColRisk}> 
                    <Text style={styles.tableCell}>Reach of Information</Text> 
                </View> 
                <View style={setColor(risks.[0].PRR5)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? risks.[0].PRR5 : ''}</Text> 
                </View> 
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColRisk}> 
                    <Text style={styles.tableCell}>Technology</Text> 
                </View> 
                <View style={setColor(risks.[0].PRR6)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? risks.[0].PRR6 : ''}</Text> 
                </View> 
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColRisk}> 
                    <Text style={styles.tableCell}>Transmission</Text> 
                </View> 
                <View style={setColor(risks.[0].PRR7)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? risks.[0].PRR7 : ''}</Text> 
                </View> 
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColRisk}> 
                    <Text style={styles.tableCell}>Breach Impact</Text> 
                </View> 
                <View style={setColor(risks.[0].PRR8)}> 
                    <Text style={styles.tableCellNum}>{checkData.current? risks.[0].PRR8 : ''}</Text> 
                </View> 
            </View>
      </View>
        )

    );
 };
    
export default Table;