import React, { useState, useEffect } from 'react';
import { Card, TextField, Typography, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@material-ui/core';
import { Container } from 'react-bootstrap';
import useStyles from './styles';
import './style.css'

const Post = ({ post }) => {

  const classes = useStyles();
  const [showRadio, setShowRadio] = useState(false)

  var rad = post.rating1

  useEffect(() => {
    if(rad===''){
      setShowRadio(true)
    }
    else{
      setShowRadio(false)
    }
  },[rad])


  const [email] = useState(JSON.parse(localStorage.getItem('profile')));
  
  const user = email?.result.email;

  const defaultState = {
    id:post._id,
    user: user,
    category: post.category,
    questionNumber: post.questionNumber,
    question: post.question,
    rate:'',
    comment:'',
    confidentiality: post.confidentiality, 
    integrity: post.integrity, 
    availability: post.availability, 
    privacy: post.privacy
  }

const Question = (post.category +" Question "+ post.questionNumber)

const currentState = JSON.parse(localStorage.getItem(Question))

const [state, setState] = useState(currentState || defaultState)

const handleChange = async (e) => {
  const newState = {...state, [e.target.name]: e.target.value}
  localStorage.setItem(Question, JSON.stringify(newState))  
  setState(newState)
}
  
  return (
    <div>
      <Card className={classes.card}>
        <Typography className={classes.title} gutterBottom variant="h5" component="h2">{post.questionNumber}). {post.question}</Typography>
        <Container className={classes.ratings}>
          <FormControl >
            <FormLabel id="demo-radio-buttons-group-label" />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={state.rate}
              name="rate"
              onChange={handleChange}
              className={showRadio? classes.hide : 'show' }
            >
              <FormControlLabel className={classes.radio} value="1" control={<Radio   />} label={post.rating1} />
              <FormControlLabel className={classes.radio} value="2" control={<Radio  />} label={post.rating2} />
              <FormControlLabel className={classes.radio} value="3" control={<Radio  />} label={post.rating3} />
              <FormControlLabel className={classes.radio} value="4" control={<Radio  />} label={post.rating4} />
              <FormControlLabel className={classes.radio} value="5" control={<Radio  />} label={post.rating5} />
            </RadioGroup>
          </FormControl>
        </Container>
        <Typography className={classes.help} variant="body2" color="textSecondary" component="p">Help: {post.help}</Typography>
        <TextField className={showRadio? classes.hide:  classes.comments  } name="comment" variant="outlined" label="Comment" multiline rows={4} defaultValue={state.comment} onChange={handleChange}/>
      </Card>
    </div>
  );
};

export default Post;
