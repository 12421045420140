import React from 'react';
import {Text, View } from "@react-pdf/renderer";
import styles from './styles'

const RiskLegends = () => {

    return (
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
                <View style={styles.tableColGrey}> 
                    <Text style={styles.tableCell}>Low:</Text> 
                </View> 
                <View style={styles.tableColLegend}> 
                    <Text style={styles.tableCell}>There is a possibility that the risk will materialize but there are mitigating factors.</Text> 
                </View>  
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColYellow}> 
                    <Text style={styles.tableCell}>Medium: </Text> 
                </View> 
                <View style={styles.tableColLegend}> 
                    <Text style={styles.tableCell}>There is a strong possibility that the risk will materialize if no corrective measures are taken.</Text> 
                </View> 
            </View>

            <View style={styles.tableRow}> 
                <View style={styles.tableColRed}> 
                    <Text style={styles.tableCell}>High:</Text> 
                </View> 
                <View style={styles.tableColLegend}> 
                    <Text style={styles.tableCell}>There is a near certainty that the risk will materialize if no corrective measures are taken.  Perform a Privacy Impact Assessment (PIA) immediately.</Text> 
                </View> 
            </View>
      </View>
        
    );
 };
    
export default RiskLegends;