import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import './styles.css'
import { getAverage} from '../../../actions/average';
import Chart from '../../Chart/Chart'

const SensitivityTable = () => {
  
  const dispatch = useDispatch();
  const average = useSelector((state) => state.averages);

  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  const toggle = useRef(false)

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  if(width<600){
    toggle.current = true;
  }
  if(width>600){
    toggle.current = false;
  }
  
  useEffect(() => {
    dispatch(getAverage());
  }, [dispatch]);

  const resolveClass = (value) => {
    let className = ''

    switch (true) {
        case (value >= 3.75):
            className = 'green'
            break;
        case (value >= 2.5):
            className = 'gray'
            break;
        case (value >= 1.25):
            className = 'yellow'
            break;
        case (value < 1.25):
            className = 'red'
            break;
        default:
            break
    }

    return className
}

  return (
 
    <div ref={ref}>
      {average.map((avg) => (
        <div  key={avg}>
          <Chart avg={avg}/>
        </div>
      ))}
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell ><b>Score Sheet</b></TableCell>
            <TableCell align="right">{toggle.current? 'Avg' : 'Average'}</TableCell>
            <TableCell align="right">{toggle.current? 'C' : 'Confidentiality'}</TableCell>
            <TableCell align="right">{toggle.current? 'I' : 'Integrity'}</TableCell>
            <TableCell align="right">{toggle.current? 'A' : 'Availability'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {average.map((row) => (
            <TableRow
              key={row.avgSr}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">Sensitivity Rating</TableCell>
                <TableCell className={resolveClass(row.avgSr.Avg)} align="right">{row.avgSr.Avg}</TableCell>
                <TableCell className={resolveClass(row.avgSrC.Avg)} align="right">{row.avgSrC.Avg}</TableCell>
                <TableCell className={resolveClass(row.avgSrI.Avg)} align="right">{row.avgSrI.Avg}</TableCell>
                <TableCell className={resolveClass(row.avgSrA.Avg)} align="right">{row.avgSrA.Avg}</TableCell>
            </TableRow>
             ))}
        </TableBody>
        
      </Table>
    </TableContainer>

    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 640 }} aria-label="simple table" >
        <TableHead>
          <TableRow>
            <TableCell><b>Controls</b></TableCell>
            <TableCell align="right">{toggle.current? 'Avg' : 'Average'}</TableCell>
            <TableCell align="right">{toggle.current? 'C' : 'Confidentiality'}</TableCell>
            <TableCell align="right">{toggle.current? 'I' : 'Integrity'}</TableCell>
            <TableCell align="right">{toggle.current? 'A' : 'Availability'}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody> 
          {average.map((row) => (
            <TableRow
              key={row.avgSr}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">Host System Controls</TableCell>
                <TableCell className={resolveClass(row.avgHsc.Avg)} align="right">{row.avgHsc.Avg}</TableCell>
                <TableCell className={resolveClass(row.avgHscC.Avg)} align="right">{row.avgHscC.Avg}</TableCell>
                <TableCell className={resolveClass(row.avgHscI.Avg)} align="right">{row.avgHscI.Avg}</TableCell>
                <TableCell className={resolveClass(row.avgHscA.Avg)} align="right">{row.avgHscA.Avg}</TableCell>
            </TableRow>
            ))}
        </TableBody>
        <TableBody>
                {average.map((row) => (
                  <TableRow
                    key={row.avgSr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row">Network & Communications Controls</TableCell>
                      <TableCell className={resolveClass(row.avgNcc.Avg)} align="right">{row.avgNcc.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgNccC.Avg)} align="right">{row.avgNccC.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgNccI.Avg)} align="right">{row.avgNccI.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgNccA.Avg)} align="right">{row.avgNccA.Avg}</TableCell>
                  </TableRow>
                  ))}
        </TableBody>
        <TableBody>
                {average.map((row) => (
                  <TableRow
                    key={row.avgSr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row">Client Workstation Controls</TableCell>
                      <TableCell className={resolveClass(row.avgCwc.Avg)} align="right">{row.avgCwc.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgCwcC.Avg)} align="right">{row.avgCwcC.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgCwcI.Avg)} align="right">{row.avgCwcI.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgCwcA.Avg)} align="right">{row.avgCwcA.Avg}</TableCell>
                  </TableRow>
                  ))}
        </TableBody>
        <TableBody>
                {average.map((row) => (
                  <TableRow
                    key={row.avgSr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row">Application (design & coding) Controls</TableCell>
                      <TableCell className={resolveClass(row.avgAdcc.Avg)} align="right">{row.avgAdcc.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgAdccC.Avg)} align="right">{row.avgAdccC.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgAdccI.Avg)} align="right">{row.avgAdccI.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgAdccA.Avg)} align="right">{row.avgAdccA.Avg}</TableCell>
                  </TableRow>
                  ))}
        </TableBody>
        <TableBody>
                {average.map((row) => (
                  <TableRow
                    key={row.avgSr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row">Environment and Physical Security Controls</TableCell>
                      <TableCell className={resolveClass(row.avgEpsc.Avg)} align="right">{row.avgEpsc.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgEpscC.Avg)} align="right">{row.avgEpscC.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgEpscI.Avg)} align="right">{row.avgEpscI.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgEpscA.Avg)} align="right">{row.avgEpscA.Avg}</TableCell>
                  </TableRow>
                  ))}
        </TableBody>
        <TableBody>
                {average.map((row) => (
                  <TableRow
                    key={row.avgSr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row">Application Continuity Controls</TableCell>
                      <TableCell className={resolveClass(row.avgAcc.Avg)} align="right">{row.avgAcc.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgAccC.Avg)} align="right">{row.avgAccC.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgAccI.Avg)} align="right">{row.avgAccI.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgAccA.Avg)} align="right">{row.avgAccA.Avg}</TableCell>
                  </TableRow>
                  ))}
        </TableBody>
        <TableBody>
                {average.map((row) => (
                  <TableRow
                    key={row.avgSr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row">Access Controls</TableCell>
                      <TableCell className={resolveClass(row.avgAc.Avg)} align="right">{row.avgAc.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgAcC.Avg)} align="right">{row.avgAcC.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgAcI.Avg)} align="right">{row.avgAcI.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgAcA.Avg)} align="right">{row.avgAcA.Avg}</TableCell>
                  </TableRow>
                  ))}
        </TableBody>
        <TableBody>
                {average.map((row) => (
                  <TableRow
                    key={row.avgSr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row">General Procedures Controls </TableCell>
                      <TableCell className={resolveClass(row.avgGpc.Avg)} align="right">{row.avgGpc.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgGpcC.Avg)} align="right">{row.avgGpcC.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgGpcI.Avg)} align="right">{row.avgGpcI.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgGpcA.Avg)} align="right">{row.avgGpcA.Avg}</TableCell>
                  </TableRow>
                  ))}
        </TableBody>
        <TableBody>
                {average.map((row) => (
                  <TableRow
                    key={row.avgSr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row"><b>Total Controls</b></TableCell>
                      <TableCell className={resolveClass(row.avgTotal)} align="right">{row.avgTotal}</TableCell>
                      <TableCell className={resolveClass(row.avgTotalC)} align="right">{row.avgTotalC}</TableCell>
                      <TableCell className={resolveClass(row.avgTotalI)} align="right">{row.avgTotalI}</TableCell>
                      <TableCell className={resolveClass(row.avgTotalA)} align="right">{row.avgTotalA}</TableCell>
                  </TableRow>
                  ))}
        </TableBody>
        <TableBody>
                {average.map((row) => (
                  <TableRow
                    key={row.avgSr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row">Business Procedures Continuity Rating</TableCell>
                      <TableCell className={resolveClass(row.avgBpcr.Avg)} align="right">{row.avgBpcr.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgBpcrC.Avg)} align="right">{row.avgBpcrC.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgBpcrI.Avg)} align="right">{row.avgBpcrI.Avg}</TableCell>
                      <TableCell className={resolveClass(row.avgBpcrA.Avg)} align="right">{row.avgBpcrA.Avg}</TableCell>
                  </TableRow>
                  ))}
        </TableBody>
    </Table>
    <Typography align="left">Legend: <span className='green'>Strong</span>, <span className='gray'>Balanced</span>, <span className='yellow'>Weak</span>, <span className='red'>Alert</span></Typography>

    
  </TableContainer>
  
</div>
  );
}

export default SensitivityTable;
