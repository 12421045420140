import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import "./styles.css";
import Form from './components/Form/Form';
import {getPosts} from './actions/posts';

const Create = () => {
  const [currentId, setCurrentId] = useState(0);  
  const dispatch = useDispatch();

  useEffect(() => {   
    dispatch(getPosts());  
  }, [currentId, dispatch]);

  return (
           
    <div className="content">                         
      <Form currentId={currentId} setCurrentId={setCurrentId} />                            
    </div>
  );
};

export default Create;
